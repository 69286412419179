import React from 'react';

import RouterOutlet from './components/routing.component.jsx'

import './App.css';

function App() {
  return (
    <div className="App">
      <RouterOutlet />
    </div>
  );
}

export default App;
