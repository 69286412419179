import React, { Component } from 'react';
import { Carousel, CarouselItem } from 'reactstrap';

import quotes from './testimonials-data.jsx'

class Testimonials extends Component {
  constructor(props){
    super(props);
    this.state = {
			activeIndex: 0,
			animating: false,
			testimonials: quotes
		}
  }

  setActiveIndex = ( index ) => {
		this.setState( { activeIndex: index } )
	}

	next = () => {
		console.log( "Triggered" )
		if ( this.state.animating )
			return;
		const nextIndex = this.state.activeIndex === this.state.testimonials.length - 1
			? 0
			: this.state.activeIndex + 1;
		this.setActiveIndex( nextIndex );
	}

	previous = () => {}

	render() {
		return ( <Carousel activeIndex={this.state.activeIndex} next={this.next} previous={this.previous} ride={'carousel'} keyboard={true} interval={7000}>
			{
				this.state.testimonials.map( ( item, index ) => {
					return ( <CarouselItem key={index}>
						<div className="row">
							<div className="col-1 quote-mark text-right">
								<span className="fa fa-fw fa-quote-left text-muted"></span>
							</div>
							<div className="col-11 testimonials-person-quote">
								<p>{item.quote}</p>
								- {item.personName}
							</div>
							<div></div>
						</div>
					</CarouselItem> )
				} )
			}
		</Carousel> )

	}
}

export default Testimonials;
