import React, { Component } from 'react';
import { Helmet } from "react-helmet";

import quotes from '../main/testimonials-data.jsx'

import './testimonials.scss'

class Testimonials extends Component {
	constructor( props ) {
		super( props );
		this.state = {
			testimonials: quotes
		}
	}

	render() {
		return ( <section className="testimonials">
		<Helmet>
			<meta charSet="utf-8"/>
			<title>Favourite Supplies - Testimonials</title>
		</Helmet>

			<div className="main">
				<div className="container">
					<div className="banner">
						<div className="jumbotron">
							<div className="container text-center my-5">
								<div>
									<span className="text-muted text-uppercase banner-text mt-3">Testimonials and Thoughts</span>
								</div>
							</div>
						</div>
					</div>

				</div>
				<div className="container pt-3">
					<div className="quotes-container-inner">
						{
							this.state.testimonials.map( ( quote, index ) => {
								return ( <div className="quote-wrapper my-5" key={index}>
									<div className="quote-wrapper-inner">
										<div className="row">
											<div className="col-sm-1 text-center quote-mark text-center">
												<span className="fa fa-fw fa-quote-left text-muted"></span>
											</div>
											<div className="col-sm-10 testimonials-person-quote text-muted">
												<p>{quote.quote}</p>
												- {quote.personName}
											</div>
											<div className="col-sm-1 text-center 1 quote-mark quote-mark-right text-center">
												<span className="fa fa-fw fa-quote-right text-muted"></span>
											</div>
										</div>
									</div>
									<hr className="mt-5 mb-3"/>

								</div> )
							} )
						}
					</div>

				</div>
			</div>
		</section> )

	}
}

export default Testimonials;
