import React, { Component } from 'react';

import './about.scss'

class About extends Component {
	render() {
		return ( <div className="container">
			<div className="section-about py-5">
				<div className="display-4">
					Dummy About Page
				</div>
				<div className="content text-muted pb-5 mt-3">
					Lorem ipsum dolor sit amet, consectetur adipisicing elit. Numquam consectetur perspiciatis labore nam ipsa accusamus nobis repudiandae sequi impedit, possimus incidunt error harum optio hic libero. Impedit cupiditate rerum doloremque.
				</div>
			</div>
		</div> )

	}
}

export default About;
