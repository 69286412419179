import React from 'react';
import { NavLink as RRNavLink } from 'react-router-dom';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import {
	Collapse,
	NavbarToggler,
	NavbarBrand,
	Nav,
	NavItem,
	NavLink
} from 'reactstrap';

import './topbar.scss'

class Topbar extends React.Component {
	constructor( props ) {
		super( props );
		this.state = {
			"collapsed": true
		}
	}

	toggleNavbar = () => {
		this.setState( {
			"collapsed": !this.state.collapsed
		} )
	}

	render() {
		return ( <nav className="navbar navbar-expand-lg navbar-light">
			<div className="container">
				<NavbarBrand href="/" className="mr-auto">
					<img src="/assets/img/logo-topbar-cropped.png" alt=""/>
				</NavbarBrand>
				<NavbarToggler onClick={this.toggleNavbar} className="mr-2"/>
				<Collapse isOpen={!this.state.collapsed} navbar={true}>
					<Nav navbar={true} className="weight-600 ml-auto">
						<NavItem>
							<NavLink exact tag={RRNavLink} activeClassName="active" className="nav-link" to="/">Home</NavLink>
						</NavItem>

						<NavItem className="nav-item nav-dropdown-wrapper">
							<UncontrolledDropdown setActiveFromChild={true}>
								<DropdownToggle tag="a" className="nav-link nav-dropdown">
									Products
								</DropdownToggle>
								<DropdownMenu className="nav-DropdownMenu">
									<DropdownItem tag="a" href="/products/favanchor">
										<div className="row">
											<div className="col-1">
												<div className="bullet color-primary">
													<span className="fa fa-fw fa-circle"></span>
												</div>
											</div>
											<div className="col-11">
												<div className="dropdown-item-text text-muted">
													<div className="">
														<img src="/assets/img/favanchor-product-text-logo.png" alt="" className="favanchor-text-logo"/>
													</div>
													<div className="">Complete Surgical Set</div>
												</div>
											</div>
										</div>
									</DropdownItem>
									<hr className="mx-3 my-2"/>
									<DropdownItem tag="a" href="/products/mini-implants">
										<div className="row">
											<div className="col-1">
												<div className="bullet color-primary">
													<span className="fa fa-fw fa-circle"></span>
												</div>
											</div>
											<div className="col-11">
												<div className="dropdown-item-text text-muted">
													<div className="">Titanium Mini Implants</div>
												</div>
											</div>
										</div>
									</DropdownItem>

								</DropdownMenu>
							</UncontrolledDropdown>
						</NavItem>

						<NavItem>
						<NavLink tag={RRNavLink} activeClassName="active" className="nav-link" to="/promotions">Promotions
						</NavLink>
						</NavItem>

						<NavItem>
							<NavLink tag={RRNavLink} activeClassName="active" className="nav-link" to="/clinical-studies">Clinical Studies</NavLink>
						</NavItem>

						<NavItem>
							<NavLink tag={RRNavLink} activeClassName="active" className="nav-link" to="/videos">Videos</NavLink>
						</NavItem>

						<NavItem>
							<NavLink tag={RRNavLink} activeClassName="active" className="nav-link" to="/testimonials">Testimonials</NavLink>
						</NavItem>

						<NavItem>
							<NavLink tag={RRNavLink} activeClassName="active" className="nav-link text-danger" to="/checkout" tabIndex={-1} aria-disabled="true">PAY HERE!</NavLink>
						</NavItem>
					</Nav>
				</Collapse>
			</div>
		</nav> )
	}
}
export default Topbar
