import React, { Component } from 'react';
import { Helmet } from "react-helmet";
// import { Route, Link, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom'

import './mini-implants.scss'

class MiniImplants extends Component {
	constructor( props ) {
		super( props );
		this.state = {
			implants: [
				{
					"image": "Golden small.jpg",
					"name": "Titanium mini implant",
					"color": "Golden",
					"size": "1.6 mm x 6 mm",
					"collarLength": "Short",
					"price": "1000"
				}, {
					"image": "Purple small.jpg",
					"name": "Titanium mini implant",
					"color": "Purple",
					"size": "1.8 mm x 6 mm",
					"collarLength": "Short",
					"price": "1000"
				}, {
					"image": "Green.jpg",
					"name": "Titanium mini implant",
					"color": "Green",
					"size": "1.8 mm x 8mm",
					"collarLength": "Regular",
					"price": "1000"
				}, {
					"image": "Pink.jpg",
					"name": "Titanium mini implant",
					"color": "Pink",
					"size": "1.6 mm x 8 mm",
					"collarLength": "Regular",
					"price": "1000"
				}, {
					"image": "Blue.jpg",
					"name": "Titanium mini implant",
					"color": "Blue",
					"size": "1.6mm x 10mm",
					"collarLength": "Regular",
					"price": "1000"
				}, {
					"image": "Copper.jpg",
					"name": "Titanium Mini Implant",
					"color": "Copper",
					"size": "1.8 mm x 10 mm",
					"collarLength": "Regular",
					"price": "1000"
				}, {
					"image": "Golden big.jpg",
					"name": "Titanium Mini Implant",
					"color": "Golden",
					"size": "1.6 mm x 12 mm",
					"collarLength": "Regular",
					"price": "1000"
				}, {
					"image": "Purple big.jpg",
					"name": "Titanium Mini Implant",
					"color": "Purple",
					"size": "1.8 mm x 12 mm",
					"collarLength": "Regular",
					"price": "1000"
				}, {
					"image": "FavB.jpg",
					"name": "Fav B SS Bone Screw",
					"color": "",
					"size": "2 mm x 12 mm",
					"collarLength": "Short",
					"info": "Buccal Shelf Screw",
					"price": "1000"
				}, {
					"image": "FavZ.jpg",
					"name": "Fav Z SS Bone screw",
					"color": "",
					"size": "2mm x 14 mm",
					"collarLength": "Long",
					"info": "Infra zygomatic screw",
					"price": "1000"
				}
			]
		}
	}

	render() {
		return ( <section className="mini-implants">
		<Helmet>
			<meta charSet="utf-8"/>
			<title>Favourite Supplies - Titanium Mini Implants</title>
		</Helmet>

			<div className="container mt-5">
				<div className="banner">
					<div className="jumbotron">
						<div className="container text-center my-5">
							<div>
								<span className="text-muted text-uppercase banner-text mt-3">Titanium Mini Implants</span>
							</div>
						</div>
					</div>
				</div>

				<div className="main">
					<div className="row">
						<div className="col-sm-6 column">
							<div className="inner-wrapper">
								{
									this.state.implants.slice( 0, 5 ).map( ( implant, index ) => {
										return ( <div className="row mini-implant-each mb-3" key={index}>
											<div className="col-md-6 implant-image">
												<div className="price text-center d-none">
													<span className="badge badge-theme">
														<span className="fa fa-fw fa-inr"></span>1000</span>
												</div>
												<div className="price price-md-plus text-center d-none">
													<span className="badge badge-theme">
														<span className="fa fa-fw fa-inr"></span>1000</span>
												</div>
												<img src={"/assets/img/products/mini-implants/" + implant.image} alt="" className=""/>
											</div>
											<div className="col-md-6 text-center">
												<div className="implant-name">
													<strong>{implant.name}</strong>
												</div>
												<div className="implant-color">
													Colour Code : {implant.color}
												</div>
												<div className="implant-size">
													Size : {implant.size}
												</div>
												<div className="implant-collar">
													Collar Length : {implant.collarLength}
												</div>
											</div>
										</div> )
									} )
								}
							</div>
						</div>

						<div className="col-sm-6 column">
							<div className="inner-wrapper">
								{
									this.state.implants.slice( 5, 10 ).map( ( implant, index ) => {
										return ( <div className="row mini-implant-each mb-3">
											<div className="col-md-6 implant-image">
												<div className="price text-center d-none" >
													<span className="badge badge-theme">
														<span className="fa fa-fw fa-inr"></span>1000</span>
												</div>
												<div className="price price-md-plus text-center d-none">
													<span className="badge badge-theme">
														<span className="fa fa-fw fa-inr"></span>1000</span>
												</div>
												<img src={"/assets/img/products/mini-implants/" + implant.image} alt="" className=""/>
											</div>
											<div className="col-md-6 text-center">
												<div className="implant-name">
													<strong>{implant.name}</strong>
												</div>
												{
													implant.color && <div className="implant-color">
															Colour Code : {implant.color}
														</div>
												}
												<div className="implant-size">
													Size : {implant.size}
												</div>
												<div className="implant-collar">
													Collar Length : {implant.collarLength}
												</div>
												{
													implant.info && <div className="implant-info">
															{implant.info}
														</div>
												}

											</div>
										</div> )
									} )
								}
							</div>
						</div>

					</div>
				</div>

			</div>
		</section> )

	}
}

export default MiniImplants;
