import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LaddaButton, { xs, SLIDE_UP } from 'react-ladda';

import { addEmail } from '../../services/spreadsheet.service.jsx';
import './footer.scss'

class Footer extends Component {
	
	constructor( props ) {
		let appPackage = require('../../../package.json');
		super( props );
		this.state = {
			loading: false,
			appVersion: appPackage.version
		};
	};

	componentDidMount() {
		// addEmail().then(response => response.json()).then(data => {
			// 		console.log(data);
			// });
		}
		
		toggleLadda = () => {
			this.setState({
			loading: !this.state.loading
		})
	}

	handleInputChange = ( event ) => {
		// console.log(event.target.value);
		let inputName = event.target.name;
		this.setState( { [ inputName ]: event.target.value } )
	}
	
	submitEmail = ( event ) => {
		event.persist();
		if ( this.state.userEmail ) {
			if ( this.state.userEmail.length > 0 ) {
				this.toggleLadda();
				addEmail( this.state.userEmail ).then( response => response.json() ).then( data => {
					this.toggleLadda();
					event.target.innerHTML = "Added!";
					event.target.diabled = true;
					console.log( event.target );
				} );
			}
		} else {}
	};



	render() {
		return ( <section className="footer">
			<div className="section-contact-strip mb-5">
				<div className="container">
					<div className="contact-strip-container">
						<div className="row">
							<div className="col-md-4 tagline pt-1">
								Let's Stay in Touch for More Offers!
							</div>
							<div className="col-lg-6 col-md-5 contact-strip-input">
								<div className="input-group">
									<input type="text" className="form-control" placeholder="Enter Your Email Address" aria-label="email-input" aria-describedby="basic-addon2" name="userEmail" onChange={this.handleInputChange}/>
								</div>
							</div>
							<div className="col-lg-2 col-md-3">
								<LaddaButton className="btn btn-block text-uppercase btn-theme-inverse weight-600" loading={this.state.loading} onClick={this.submitEmail} data-color="#eee" data-size={xs} data-style={SLIDE_UP} data-spinner-size={30} data-spinner-color="#fff" data-spinner-lines={12}>
									Add Me!
								</LaddaButton>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="footer-actual-wrapper pt-4">
				<div className="container">
					<div className="section-footer-actual">
						<div className="row">
							<div className="col-sm-4">
								<div className="touch">
									<div className="header">
										Get in touch
									</div>
									<div className="content text-muted mb-4">
										+91 93240 87948
										<br/>
										+91 98907 99594
										<br/>
										+91 99302 38688
										<br/>
										Need to talk? We're here to help you!
									</div>
								</div>
								<div className="write">
									<div className="header">
										Write to us
									</div>
									<div className="content text-muted mb-4">
										<strong>favanchor@gmail.com</strong>
										<br/>
										For your questions,
										<br/>
										We're just an email away.
									</div>
								</div>
								<div className="help">
									<div className="header">
										Favourite Supplies,
									</div>
									<div className="content text-muted mb-5">
										Shop No. 2, Raj Roshan. Soc. Louiswadi, Thane West, Maharashtra - 400 604.

									</div>
								</div>
							</div>

							<div className="col-sm-3 smoothen-transition">
								<div className="information">
									<div className="header">
										Information
									</div>
									<div className="content site-links text-muted mb-4">
										<p>
											<Link to="/tracking">Order Tracking</Link>
										</p>
										<p>
											<Link to="/testimonials">Customer Reviews</Link>
										</p>
										<p>
											<Link to="/offers">Special Offers</Link>
										</p>
										<p>
											<Link to="/sitemap">Sitemap</Link>
										</p>
										<p>
											<Link to="/blog">Blog</Link>
										</p>
									</div>
								</div>

							</div>

							<div className="col-sm-4 smoothen-transition">
								<div className="information">
									<div className="header">
										Legal
									</div>
									<div className="content site-links text-muted mb-4">
										<p>
											<Link to="/legal/privacy-policy">Privacy Policy</Link>
										</p>
										<p>
											<Link to="/legal/terms-of-service">Terms of Service</Link>
										</p>
									</div>
								</div>

							</div>
						</div>
					</div>
					<hr/>
					<div className="section-footer-extended mb-5 smoothen-transition">
						<div className="inner-text py-4">
							<span className="text-theme-primary">Add us on social media :
							</span>
							<div className="d-lg-none mt-2"></div>
							<span className="social social-large text-muted ml-2">
								<span className="icon-wrapper rounded-circle">
									<span className="fa fa-fw fa-facebook"></span>
								</span>
								<span className="icon-wrapper rounded-circle">
									<span className="fa fa-fw fa-instagram"></span>
								</span>
								<span className="icon-wrapper rounded-circle">
									<span className="fa fa-fw fa-youtube-play"></span>
								</span>
								<span className="icon-wrapper rounded-circle">
									<span className="fa fa-fw fa-twitter"></span>
								</span>
								<span className="icon-wrapper rounded-circle">
									<span className="fa fa-fw fa-pinterest"></span>
								</span>
							</span>

						</div>
					</div>
					<div className="text-muted small d-flex">
						<div className="ml-auto">V{this.state.appVersion}</div>
					</div>
				</div>
			</div>

		</section> )

	}
}

export default Footer;
