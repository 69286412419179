export function createOrder(state) {

	var data = {
		"amount": state.amount * 100,
		"payee": {
			"name": state.fName + " " + state.lName,
			"email": state.email,
			"phone": state.phone,
			"address": state.address,
			"comments": state.comments
		}
	};
	let options = {
		method: 'POST', // *GET, POST, PUT, DELETE, etc.
		credentials: 'omit', // include, *same-origin, omit
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(data) // body data type must match "Content-Type" header
	}

	return fetch( 'https://favsupplies.azurewebsites.net/api/razor_createorder?code=a91PZ4cFczuUsW5NVuBYxFayif6aJzipPk6L4OoJeODZ6ka5yYbx/Q==', options );

}
