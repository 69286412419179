import React, { Component } from 'react';
import { Helmet } from "react-helmet";

import './favanchor.scss'

class FavAnchor extends Component {
	render() {
		return ( <section className="product-favanchor">
		<Helmet>
			<meta charSet="utf-8"/>
			<title>Favourite Supplies - FavAnchor Skeletal Anchorage System</title>
			<meta name="description" content="The TAD range has design based on sound biological rules and the drivers have been made with principles of ergonomics. A complete system in every aspect which promises best of quality at most economical price range."/>
		</Helmet>

			<div className="container">
				<div className="banner">
					<div className="jumbotron">
						<div className="container text-center my-5">
							<div>
								<img src="/assets/img/favanchor-product-text-logo.png" alt="" className=""/>
							</div>
							<div>
								<span className="text-muted text-uppercase banner-text mt-3">Skeletal Anchorage System</span>
							</div>
						</div>
					</div>
				</div>

				<div className="main">
					<div className="whole-product-wrapper">
						<div className="row product-whole mb-5">
							<div className="col-sm-6">
								<img src="/assets/img/products/favanchor/product-main.png" alt=""/>
							</div>
							<div className="col-sm-6">
								<div className="image-text-wrapper">
									<div className="image-text-header">
										FavAnchor Skeletal Anchorage System
									</div>
									<div className="image-text-content">
										The TAD range has design based on sound biological rules and the drivers have been made with principles of ergonomics. A complete system in every aspect which promises best of quality at most economical price range.
									</div>
								</div>
							</div>
						</div>

						<div className="row product-element pt-5">
							<div className="col-sm-8">
								<div className="image-text-wrapper">
									<div className="image-text-header">
										Lil’One small hand driver
									</div>
									<div className="image-text-content">
										Small size for easy use in narrow & difficult to access spaces. One of a kind ring holder for easy grip, assures no instrument movement during use. Wider, deep grooves for easy implant torqueing with fingers or using an anterior debonding pliers.
									</div>
								</div>
							</div>
							<div className="col-sm-4">
								<img src="/assets/img/products/favanchor/1.png" alt=""/>
							</div>
						</div>

						<div className="row product-element">
							<div className="col-sm-8">
								<div className="image-text-wrapper">
									<div className="image-text-header">
										Contraangle hand piece driver tip
									</div>
									<div className="image-text-content">
										Small size. Perfect for using with contrangle hand pieces.
									</div>
								</div>
							</div>
							<div className="col-sm-4">
								<img src="/assets/img/products/favanchor/2.png" alt=""/>
							</div>
						</div>

						<div className="row product-element">
							<div className="col-sm-8">
								<div className="image-text-wrapper">
									<div className="image-text-header">
										IDO Tips
									</div>
									<div className="image-text-content">
										India’s only commercially available micro-osteo perforation device. Compatible with the FavAnchor Straight hand driver. Made from implant grade SS. Can be used for up to 25 perforations in a single patient. Research based 1.2 mm x 4 mm Size disposable tips.
									</div>
								</div>
							</div>
							<div className="col-sm-4">
								<img src="/assets/img/products/favanchor/3.png" alt=""/>
							</div>
						</div>

						<div className="row product-element">
							<div className="col-sm-8">
								<div className="image-text-wrapper">
									<div className="image-text-header">
										Straight Hand Driver
									</div>
									<div className="image-text-content">
										Ergonomic, unique mushroom shape. Smooth clutch action. Wide anti-slip palm grip. Finger holds designed to grip effectively with wet, gloved fingers.
									</div>
								</div>
							</div>
							<div className="col-sm-4">
								<img src="/assets/img/products/favanchor/4.png" alt=""/>
							</div>
						</div>

						<div className="row product-element">
							<div className="col-sm-8">
								<div className="image-text-wrapper">
									<div className="image-text-header">
										Cortical drill
									</div>
									<div className="image-text-content">
										0.9 mm diameter drill. For hard cortical bone and re-insertion situations.
									</div>
								</div>
							</div>
							<div className="col-sm-4">
								<img src="/assets/img/products/favanchor/5.png" alt="" className="cortical-drill" width={"200px"}/>
							</div>
						</div>

						<div className="row product-element">
							<div className="col-sm-8">
								<div className="image-text-wrapper">
									<div className="image-text-header">
										Measuring block
									</div>
									<div className="image-text-content">
										To check the length and diameter of the implants if needed. To check the insertion depth of the drill, before insertion. To check the probe length for determining the soft tissue thickness with bone sounding.
									</div>
								</div>
							</div>
							<div className="col-sm-4">
								<img src="/assets/img/products/favanchor/6.png" alt=""/>
							</div>
						</div>

						<div className="promo mt-5 pt-5">
							{/* <img src="/assets/img/products/favanchor/promo.png" alt=""/> */}
						</div>

					</div>

				</div>

				<div className="bottom-gutter my-5"></div>
			</div>

		</section> )

	}
}

export default FavAnchor;
