import React, { Component } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import Topbar from './topbar/topbar.component.jsx'
import Footer from './footer/footer.component.jsx'

import Main from './main/main.component.jsx'
import About from './about/about.component.jsx'
import FavAnchor from './products/fav-anchor/favanchor.component.jsx'
import MiniImplants from './products/mini-implants/mini-implants.component.jsx'
import ClinicalStudies from './clinical-studies/clinical-studies.component.jsx'
import Testimonials from './testimonials/testimonials.component.jsx'
import Order from './order/order.component.jsx'

import PrivacyPolicy from './legal/privacy.component.jsx'
import TermsofService from './legal/serviceTerms.component.jsx'

import NotFound from './404.component.jsx'

class RouterOutlet extends Component {
	constructor( props ) {
		super( props );
	}

	render() {
		return ( <div>

			<div className="router-outlet">
				<Router>
					<Topbar/>
					<Switch>
						<Route exact path="/" component={Main}/>
						<Route exac path="/home" component={Main}/>
						{/* <Route exac path="/about" component={About}/>
								<Redirect exact from="*" to={"/"}/> */}
						<Route exac path="/products/favanchor" component={FavAnchor}/>
						<Route exac path="/products/mini-implants" component={MiniImplants}/>
						<Route exac path="/clinical-Studies" component={ClinicalStudies}/>
						<Route exac path="/testimonials" component={Testimonials}/>
						<Route exac path="/checkout" component={Order}/>
						<Route exac path="/legal/privacy-policy" component={PrivacyPolicy}/>
						<Route exac path="/legal/terms-of-service" component={TermsofService}/>

						<Route component={NotFound}/>
					</Switch>
					<Footer/>
				</Router>
			</div>

		</div> )

	}
}

export default RouterOutlet;
