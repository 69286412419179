var quotes = [
  {
    quote: 'The FavAnchor TADs are excellent in quality, supplied in a well designed kit. They are easy to use and I am proud that these Indian TADs in every respect match the best TADs made anywhere else in the world. I have used 5 TAD systems before FavAnchor and am happy to switch completely to this system.',
    personName: 'Dr. Joe Verghese - Cochin.'
  }, {
    quote: 'Excellently conceptualized versatile system. FavAnchor is the most versatile TAD system I have used in the world so proud that it’s Indian.',
    personName: 'Dr. Nikhilesh Vaid - Mumbai.'
  }, {
    quote: 'Indigenously developed, manufactured and marked FavAnchor miniscrews are top quality and value for money and seem to have answered the prayers of all Indian Orthodontists of having an economic but superior quality miniscrew.',
    personName: 'Dr. Shailesh Deshmukh - Pune.'
  }, {
    quote: 'FavAnchor Micro implants, in the shortest possible time, have become the favourite for my patients. The implant head, the grip, the driver has on it, the smooth penetration and unobtrusive release of the driver from the micro - implant head is what made me fall in love with it.Lovely experience.World class micro - implant !Made in India !Made for  the world !!',
    personName: "Dr. Biju Abraham- Mumbai."
  }, {
    quote: 'I really like the FavAnchor system and feel you guys have done a marvelous job designing as well as production. It has excellent quality and versatility. The IDO tip is a very handy tool for accelerated orthodontics.',
    personName: 'Dr. Rushitom Mistry - Mumbai.'
  }, {
    quote: 'I have started using FavAnchor recently. the implant insertion is “LIKE A BREEZE”. International Quality product, perfect example of "Made in India, product" with "OUR RANGE OF PRICING".',
    personName: 'Dr. Venkata Yudhishtir Palla - Hyderabad.'
  }, {
    quote: 'The best Anchorage system developed in India having all the international standards. They don\'t only sell the product to you but also provide excellent training sessions and after sales services and technical support.',
    personName: 'Dr. Piyush Chopde - Nashik.'
  }, {
    quote: 'I have used many mini implant systems, and was unhappy with design and failures. After using FavAnchor, I have doubled my implant uses. it’s excellent in all aspect.',
    personName: 'DR. Venkat Ambekar - Anantapur.'
  }
]

export default quotes;
