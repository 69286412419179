import React, { Component } from 'react';
import { Carousel, CarouselItem } from 'reactstrap';

class ImageCarousel extends Component {

	constructor(props) {
		super(props);
		this.state = {
			activeIndex: 0,
			animating: false,
			images: [
				// {
				// 	src: './assets/img/banners/banners3.jpg',
				// 	caption: ''
				// },
				{
					src: './assets/img/banners/banners2.jpg',
					caption: ''
				},
				// {
				// 	src: './assets/img/banners/banners1.jpg',
				// 	caption: ''
				// }
			]
		}
	}

	setActiveIndex = (index) => {
		this.setState({ activeIndex: index })
	}

	next = () => {
		if (this.state.animating)
			return;
		const nextIndex = this.state.activeIndex === this.state.images.length - 1
			? 0
			: this.state.activeIndex + 1;
		this.setActiveIndex(nextIndex);
	}

	previous = () => { }

	render() {
		return (<Carousel activeIndex={this.state.activeIndex} next={this.next} previous={this.previous} ride={'carousel'} keyboard={true} interval={6000}>
			{
				this.state.images.map((item, index) => {
					return (<CarouselItem key={index} className="image-wrapper">
						<img src={item.src} alt={item.src} className="image-actual" style={{
							width: '100%',
						}} />
					</CarouselItem>)
				})
			}
		</Carousel>)

	}
}

export default ImageCarousel;
