import React, { Component } from 'react';
import { Helmet } from "react-helmet";
// import { Route, Link, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom'

class NotFound extends Component {
	render() {
		return ( <section>
			<Helmet>
				<meta charSet="utf-8"/>
				<title>Favourite Supplies - 404 Page Not Found</title>
			</Helmet>

			<div className="container">
				<div className="section-about py-5">
					<div className="display-4">
						404 - Not Found
					</div>
					<div className="content text-muted pb-5 mt-3">
						Bummer!
						<br/>
						This page is currently under construction and / or is not available.
						<br/>
						Sorry about that!
					</div>
				</div>
			</div>
		</section> )

	}
}

export default NotFound;
