import React, { Component } from 'react';
import { Helmet } from "react-helmet";

import Testimonials from './testimonials.component.jsx'
import ImageCarousel from './imageCarousel.component.jsx'

import './main.scss';

class Main extends Component {

	constructor( props ) {
		super( props );
		this.state = {}
	}

	render() {
		return ( <section className="main">
			<Helmet>
				<meta charSet="utf-8"/>
				<title>Favourite Supplies - FavAnchor Skeletal Anchorage System</title>
				<meta name="description" content="Favourite Supplies led by Dr. Vivek patni, are the inventors of the FavAnchor Skeletal Anchorage System. Developed after extensive research of literature, pros and cons of various available systems, clinical uses and instrument ergonomics, and manufactured in the state of the art production facility, using best materials with top of the line machinery."/>
			</Helmet>

			<div className="container main-caraousel">
				<ImageCarousel/>
			</div>
			<div className="container">

				<div className="section-passion my-5 py-5 ">
					<div className="row text-center">

						<div className="col-sm-12">
							<div className="section-tagline weight-600 mb-5">
								Passion for Perfection!
							</div>
							<div className="section-content text-muted weight-500">
								<p>FavAnchor Skeletal Anchorage System is a complete system in all aspects. Developed after extensive research of literature, pros and cons of various available systems, clinical uses and instrument ergonomics, and manufactured in the state of the art production facility, using best materials with top of the line machinery. Each part of the system carries the stamp of extensive clinical experience of the inventors and assurance of the tradition in high quality production, maintained over half a century, by S H Pitkar Orthotools Pvt. Limited, Pune, India’s largest manufacturer and exporter of orthopedic and neurosurgical implants and instruments.</p>
							</div>
						</div>
					</div>
				</div>

				<div className="section-inventors my-5">
					<div className="row">
						<div className="col-md-6 d-md-none">
							<div className="inventors-image pl-5">
								<img src="./assets/img/DrPatni-collage.png" alt="" className="inventors-image-inner"/>
							</div>
						</div>
						<div className="col-sm-1"></div>
						<div className="col-sm-5">
							<div className="inventors-info weight-500 text-muted">
								<div className="color-primary inventors-name">Dr.(Prof.) Vivek Patni,
								</div>
								<p>
									The inventor of the FavAnchor system, did his MDS from Govt. Dental college, Mumbai. He has done Fellowship in Oral Implantology from Maharashtra Univ. of Health Sciences. He has extensive experience in Orthodontics using various TAD systems and of various dental implant systems. He is actively involved in clinical research and academics.</p>
							</div>
						</div>
						<div className="col-md-6 d-none d-md-block">
							<div className="inventors-image pl-5">
								<img src="./assets/img/DrPatni-collage.png" alt="" className="inventors-image-inner"/>
							</div>
						</div>
					</div>

					<div className="row mt-4">
						<div className="col-md-6 text-right">
							<div className="inventors-image">
								<img src="./assets/img/DrPotnis-collage.png" alt="" className="inventors-image-inner"/>
							</div>
						</div>
						<div className="col-sm-5">
							<div className="inventors-info weight-500 text-muted">
								<div className="color-primary inventors-name">Dr.(Prof.) Sheetal Potnis,</div>
								<p>
									The co-inventor of FavAnchor system, did her MDS from Govt. Dental College, Mumbai. She also holds Post Graduate Diploma in Clinical Research and Post Graduate Diploma in Health Care and Hospital Management. She has extensive experience in clinical orthodontics and academic research.</p>
							</div>
						</div>
					</div>

				</div>

				<div className="section-manufacturer my-5 pt-4">
					<div className="row justify-content-center mb-5">
						<div className="col-3">
							<div className="separator">
								<hr/>
							</div>
						</div>
					</div>

					<div className="row manufacturer-info-wrapper">
						<div className="col-sm-3">
							<div className="manufacturer-image pt-3 text-center">
								<img src="./assets/img/pitkar-logo.png" alt=""/>
							</div>
						</div>
						<div className="col-sm-9">
							<div className="manufacturer-name weight-600">
								Our Manufacturer
							</div>
							<div className="manufacturer-info weight-500 text-muted">
								<p>The engineering designing, material testing and production is done at S.H. Pitkar Orthotools Limited (Pune). Started in 1950s, the company is well known worldwide for high quality products in orthopaedics and neuro surgery. SHPOPL was the first Indian company to comply with FDA rules in 2006. The company holds manufacturing license for medical devices. SHPOPL has been awarded ISO 9001-2008 quality system since year 2000 and has been certified for ISO-13485 since 2004. CE certificate was obtained for exporting to Europe.</p>
								<p>The raw material is sourced from top of the line international suppliers, and a highly trained workforce uses state of the art machinery to create world class products in four production plants. Apart from india, the exports are to entire Europe, North America, South America, Middle East, South Asia and Africa, a truly home grown multinational.</p>
							</div>
						</div>
					</div>

					<div className="row justify-content-center mt-5">
						<div className="col-3">
							<div className="separator">
								<hr/>
							</div>
						</div>
					</div>

				</div>

			</div>

			<div className="section-testimonials mb-5">
				<div className="container testimonials-container py-5">
					<Testimonials/>
				</div>

			</div>

		</section> )

	}
}

export default Main;
