import React, { Component } from 'react';
import { Helmet } from "react-helmet";

import './clinical-studies.scss'

class ClinicalStudies extends Component {

	constructor( props ) {
		super( props );
		this.state = {
			"publications": [
				{
					"title": "Non-Surgical, Minimally Invasive, Bone-Borne Rapid Palatal Expansion for Orthopaedic Correction of Transverse Maxillary Deficiency in Young Adults: A Review",
					"authors": "Patni VJ, Kolge NE, Potnis SS, Patil RD, Lokhande AP and Sawant HR",
					"citation": "Patni VJ, Kolge NE, Potnis SS, Patil RD, Lokhande AP, et al. Non-Surgical, Minimally Invasive, Bone-Borne Rapid Palatal Expansion for Orthopaedic Correction of Transverse Maxillary Deficiency in Young Adults: A Review. Ann Dent Oral. 2019;1(1): 101.",
					"link": "/assets/pdfs/publications/ADO-1-101.pdf"
				}, {
					"title": "A simplified method for measurement of palatal bone thickness to select the optimum length of orthodontic mini-implant ",
					"authors": "Vivek J. Patni, Swapnagandha R. Kate, Sheetal S. Potnis, Neeraj E. Kolge",
					"citation": "Patni VJ, Kate SR, Potnis SS, Kolge NE. A simplified method for measurement of palatal bone thickness to select the optimum length of orthodontic mini-implant. APOS Trends Orthod 2019;9(1):1-7.",
					"link": "/assets/pdfs/publications/APOS_36_18R3_OA.pdf"
				}, {
					"title": "IDO Tips: Accelerated Orthodontics Made Easy ",
					"authors": "atni VJ, Potnis SS, Pharande SV, Patil RD, Kolge NE",
					"citation": "",
					"link": "/assets/pdfs/publications/ART20181417.pdf"
				}, {
					"title": "Semi-permanent Substitution of Maxillary Anterior Teeth: TADs Supported Approach",
					"authors": "Neeraj E. Kolge, Vivek J. Patni, and Sheetal Potnis",
					"citation": "",
					"link": "/assets/pdfs/publications/JIOS2019.pdf"
				}, {
					"title": "Pursuit for Optimum Skeletal Expansion: Case Reports on Miniscrew Assisted Rapid Palatal Expansion (MARPE)",
					"authors": "Neeraj Eknath Kolge, Vivek J Patni, Sheetal S Potnis, Swapnagandha Ravindra Kate, Floyd Stanley Fernandes and Chetna Dadarao Sirsat",
					"citation": "Kolge NE, Patni VJ, Potnis SS, Kate SR, Fernandes FS, et al. (2018) Pursuit for Optimum Skeletal Expansion: Case Reports on Miniscrew Assisted Rapid Palatal Expansion (MARPE). J Orthod Endod Vol.4 No.2:9",
					"link": "/assets/pdfs/publications/pursuit-for-optimum-skeletal-expansion.pdf"
				}
			]
		}
	}

	render() {
		return ( <section className="clinical-studies">
		<Helmet>
			<meta charSet="utf-8"/>
			<title>Favourite Supplies - Clinical Studies</title>
		</Helmet>

			<div className="container">
				<div className="banner">
					<div className="jumbotron">
						<div className="container text-center my-5">
							<div>
								<span className="text-muted text-uppercase banner-text mt-3">Clinical Studies and Publications</span>
							</div>
						</div>
					</div>
				</div>

			</div>
			<div className="main">
				<div className="container publications-container mt-5 py-4">
					<div className="publication-each">
						{
							this.state.publications.map( ( publication, index ) => {
								return ( <div className="publication-wrapper" key={index}>
									<div className="row">
										<div className="col-sm-3 display-2 color-primary text-center">
											<span className="fa fa-fw fa-file-pdf-o publication-icon"></span>
										</div>
										<div className="col-sm-9">
											<div className="weight-400">
												<div className="publication-header">
													{publication.title}
												</div>
												<div className="publication-details text-muted">
													{publication.authors}
												</div>
												<div className="citation small mt-2">
													<strong>Citation:</strong>&nbsp; {publication.citation}
												</div>
												<div className="actions mt-4">
													<a className="btn btn-outline-secondary" href={publication.link} target="_blank" rel="noopener noreferrer nofollow">
														<span className="fa fa-fw fa-eye"></span>
														View
													</a>
													<a className="btn btn-outline-theme ml-3" href={publication.link} download="download">
														<span className="fa fa-fw fa-download"></span>
														Download
													</a>
												</div>
											</div>
										</div>
									</div>
									<hr className="my-5"/>
								</div> )
							} )
						}
					</div>
				</div>
			</div>
		</section> )

	}
}

export default ClinicalStudies;
