import React, { Component } from 'react';
import LaddaButton, { xs, SLIDE_LEFT } from 'react-ladda';
import {
	FormGroup,
	Label,
	Input,
	FormFeedback
} from 'reactstrap';
import { createOrder } from '../../services/razorpay.service.jsx'

import './order.scss';

class Order extends Component {

	constructor( props ) {
		super( props );
		this.state = {
			submitting: false,
			orderData: null,
			fName: null,
			lName: null,
			amount: 0,
			email: null,
			phone: null,
			address: null,
			comments: null,
			paid: false,
			checkoutData: null
		};
	};

	checkout = ( orderData, parent ) => {
		var options = {
			"key": "rzp_live_4Q5IxdkV5uRRRf", // Enter the Key ID generated from the Dashboard
			"amount": orderData.amount_due, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise or INR 500.
			"currency": "INR",
			"name": "Favourite Supplies",
			"description": "Favourite Supplies Ltd",
			"image": "./assets/img/logo-topbar-cropped.png",
			"order_id": orderData.id, //Create an Order using Orders API. (https://razorpay.com/docs/payment-gateway/orders/integration/#step-1-create-an-order).
			"handler": function( response ) {
				parent.setState( { checkoutData: response, paid: true } );
			},
			"prefill": {
				"name": orderData.notes.name,
				"email": orderData.notes.email,
				"contact": orderData.notes.phone
			},
			"notes": orderData.notes,
			"theme": {
				"color": "#ff0000"
			}
		};
		var rzp1 = new window.Razorpay( options );
		rzp1.open();
	}

	submit = ( e ) => {
		this.setState({submitting: true});
		// let form = e.target;
		e.preventDefault();
		createOrder( this.state ).then( response => response.json() ).then( ( data ) => {
			this.setState( { orderData: data, submitting: false } );
			this.checkout( data, this );
		} ).catch( ( error ) => {
			console.error( "error", error );
		} )
	}

	handleInputChange = ( event ) => {
		// console.log(event.target.value);
		let inputName = event.target.name;
		if(inputName === 'phone'){
			this.setState( { [ inputName ]: event.target.value.replace(/\s/g,'') } )
		}else{
			this.setState( { [ inputName ]: event.target.value } )
		}
		console.log(this.state);
	}


	componentDidMount() {}

	render() {
		return ( <section className="checkout">
			<div className="container">
				<div className="banner">
					<div className="jumbotron">
						<div className="container text-center my-5">
							<div>
								<span className="text-muted text-uppercase banner-text mt-3">PAYMENT AND CHECKOUT</span>
							</div>
						</div>
					</div>
				</div>

			</div>

			<div className="main">
				<div className="container checkout-container mt-5">
					<div className="row">
						<div className="col-md-9">
							<div className="checkout-header header">
								Pay and Checkout
							</div>
							<div className="checkout-content text-muted content">
								This page allows you to pay for the orders that you have already placed with us.
								<br/>
								If you haven't placed your order yet, you can do so by contacting us through any of the contact options mentioned here.
							</div>
							<div className="checkout-form text-muted">
								<form onSubmit={( e ) => this.submit( e )}>
									<div className="row mt-4">
										<div className="col-md-4">
											<FormGroup>
												<Label for="exampleEmail">First Name</Label>
												<Input type="text" name="fName" id="firstName" placeholder="Matt" onChange={this.handleInputChange} disabled={this.state.paid} required={true}/>
												<FormFeedback>This is Required</FormFeedback>
											</FormGroup>
										</div>
										<div className="col-md-4">
											<FormGroup>
												<Label for="exampleEmail">Last Name</Label>
												<Input type="text" name="lName" id="lastName" placeholder="Damon" onChange={this.handleInputChange} disabled={this.state.paid} required={true}/>
												<FormFeedback>This is Required</FormFeedback>
											</FormGroup>
										</div>
										<div className="col-md-4">
											<FormGroup>
												<Label for="exampleEmail">Amout to be paid&nbsp;
													<small>
														(<span className="fa fa-fw fa-rupee"></span>)
													</small>
												</Label>
												<Input type="number" name="amount" id="amount" placeholder="5000" onChange={this.handleInputChange} disabled={this.state.paid} required={true}/>
											</FormGroup>
										</div>
									</div>
									<div className="row mt-2">
										<div className="col-md-7">
											<FormGroup>
												<Label for="exampleEmail">Email</Label>
												<Input type="email" name="email" id="email" placeholder="matt.damon@example.com" onChange={this.handleInputChange} disabled={this.state.paid} required={true}/>
											</FormGroup>
										</div>
										<div className="col-md-5">
											<FormGroup>
												<Label for="exampleEmail">Phone&nbsp;
													<small>(e.g: +919876543210)</small>
												</Label>
												<Input type="text" name="phone" id="phone" placeholder="+919876543210" onChange={this.handleInputChange} disabled={this.state.paid} required={true}/>
											</FormGroup>
										</div>
									</div>
									<div className="row">
										<div className="col-md-12">
											<FormGroup>
												<Label for="exampleText">Address</Label>
												<Input type="textarea" name="address" rows="1" id="address" onChange={this.handleInputChange} disabled={this.state.paid}/>
											</FormGroup>
										</div>
										<div className="col-md-12">
											<FormGroup>
												<Label for="exampleText">Comments</Label>
												<Input type="textarea" name="comments" id="comments" onChange={this.handleInputChange} disabled={this.state.paid}/>
											</FormGroup>
										</div>
									</div>
									<div className="row mt-3 mb-5">
										{
											!this.state.paid
												? ( <div className="col-12 text-right">
													<LaddaButton className="btn btn-theme" loading={this.state.submitting} data-color="#eee" data-size={xs} data-style={SLIDE_LEFT} data-spinner-size={30} data-spinner-color="#fff" data-spinner-lines={12} type="submit">
														Pay
													</LaddaButton>
												</div> )
												: ( <div className="col-12 py-4 text-center">
													<div className="header">
														Thank you!
													</div>
													<div className="content">
														Your payment has been received!
														<br/>
														For your reference, your Receipt Number is :
														<strong>{this.state.orderData.receipt}</strong>
														<br/>
													</div>
												</div> )
										}

									</div>
								</form>
							</div>
						</div>

						<div className="col-md-3 d-none d-md-block">
							<div className="contact-header">
								<div className="header">
									Contact Us
								</div>
								<div className="content text-muted mb-4">
									+91 93240 87948
									<br/>
									+91 98907 99594
									<br/>
									+91 99302 38688
								</div>
							</div>
							<div className="write">
								<div className="header">
									Email Us
								</div>
								<div className="content text-muted mb-4">
									favanchor@gmail.com
								</div>
							</div>
							<div className="help">
								<div className="header">
									Address
								</div>
								<div className="content text-muted mb-5">
									Shop No. 2,
									<br/>
									Raj Roshan. Soc. Louiswadi,
									<br/>
									Thane West,
									<br/>
									Maharashtra - 400 604.

								</div>
							</div>
						</div>

					</div>

				</div>
			</div>

		</section> )

	}
}

export default Order;
